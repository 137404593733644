<template>
  <b-container v-can="'users.view'">
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Listado De Usuarios</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              variant="primary"
              :to="{ name: 'users.register' }"
              v-can="'users.create'"
              >Registrar Nuevo</b-button
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" class="my-3">
                <b-form-group
                  label="Buscar:"
                  label-for="filter-input"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-input
                      :disabled="
                        !Array.isArray(rows) ||
                        (rows.length === 0 && isBusy === false)
                      "
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Buscar usuarios"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12" class="table-responsive">
                <b-table
                  hover
                  :items="rows"
                  :fields="columns"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :busy="
                    !Array.isArray(rows) || rows.length === 0
                      ? true
                      : false || isBusy == true
                  "
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="isBusy">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> Cargando datos...</strong>
                      </div>
                      <span class="h5" v-else>Sin registros</span>
                    </div>
                  </template>
                  <template v-slot:cell(avatar)="data">
                    <b-avatar
                      md="5"
                      class="ml-3 mr-0"
                      size="3rem"
                      variant="primary"
                      :src="data.item.avatar"
                    ></b-avatar>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                     v-b-tooltip.hover
                        title="Permisos"
                      variant=" iq-bg-success mr-1 pr-1"
                      size="sm"
                      :to="{
                        name: 'users.permissions',
                        params: { id: data.item.id },
                      }"
                    >
                      <i class="ri-lock-2-line"></i>
                    </b-button>
                      <b-button
                       v-b-tooltip.hover
                        title="Ver detalles"
                      v-can="'users.view'"
                      variant=" iq-bg-primary mr-1 pr-1 pb-2"
                      size="sm"
                      :to="{ name: 'users.show', params: { id: data.item.id } }"
                    >
                      <i class="ri-eye-line"></i>
                    </b-button>
                    <b-button
                     v-b-tooltip.hover
                        title="Editar"
                      variant=" iq-bg-warning mr-1 pr-1 pb-2"
                      size="sm"
                      :to="{ name: 'users.edit', params: { id: data.item.id } }"
                      v-can="'users.update'"
                    >
                      <i class="ri-ball-pen-fill"></i>
                    </b-button>
                    <b-button
                     v-b-tooltip.hover
                        title="Eliminar"
                      v-can="'users.delete'"
                      variant=" iq-bg-danger pr-1"
                      size="sm"
                      @click="showMsgBoxTwo(data.item.id)"
                      ><i class="ri-delete-bin-line"></i>
                    </b-button>
                  </template>
                </b-table>
                <b-pagination
                  v-if="lengt > perPage"
                  class="mt-3"
                  v-model="currentPage"
                  :total-rows="lengt"
                  :per-page="perPage"
                  first-number
                  pills
                  size="sm"
                  align="center"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapActions } from 'vuex'
import checkPermission from '@/apis/Check-permission'
export default {
  name: 'UserList',
  async mounted () {
    this.$store.commit("SET_BREADCRUMB", this.items);
    core.index()
    this.isBusy = true
    const check = await checkPermission('users.view')
    if (check) {
      const users = await this.$store.dispatch('user/fetchUsers')
      this.rows = users.map((item) => ({
        id: item.id,
        avatar: item.info.avatar,
        firstname: item.info.firstname,
        lastname: item.info.lastname,
        rut: item.info.rut,
        email: item.email,
        editable: false
      }))
    }
    this.isBusy = false
  },
  methods: {
    ...mapActions({
      destroyUsers: 'user/destroyUsers'
    }),
    showMsgBoxTwo(item) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("¿Desea eliminar este registro?.", {
          title: "Confirmación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          value ? this.remove(item) : "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async remove (item) {
      const check = await checkPermission('users.delete')
      if (check) {
        try {
          this.$store.commit('run')
          await this.destroyUsers(item)
          const index = this.rows.indexOf(item)
          this.rows.splice(index, 1)
          this.$store.commit('stop')
          core.showSnackbar('success', 'Registro eliminado correctamente')
          const users = await this.$store.dispatch('user/fetchUsers')
          this.rows = users.map((item) => ({
            id: item.id,
            avatar: item.info.avatar,
            firstname: item.info.firstname,
            lastname: item.info.lastname,
            rut: item.info.rut,
            email: item.email,
            editable: false
          }))
        } catch (error) {
          console.log(error)
        }
      }
    }
  },
  data () {
    return {
      columns: [
        { label: "Imagen", key: "avatar", class: "text-right" },
        { label: "Nombre", key: "firstname", class: "text-center" },
        { label: "Apellidos", key: "lastname", class: "text-center" },
        { label: "RUT", key: "rut", class: "text-center" },
        { label: "Correo Electrónico", key: "email", class: "text-center" },
        { label: "Acciones", key: "action", class: "text-center" }
      ],
      rows: [],
      perPage: 10,
      currentPage: 1,
      items: [
        {
          html: 'Inicio',
          to: '/'
        },
        {
          text: 'Listado de Usuarios',
          active: true
        }
      ],
      filter: null,
      isBusy: false,
      boxOne: "",
      boxTwo: "",
    }
  },
  computed: {
    lengt () {
      return this.rows.length
    }
  }
}
</script>
<style>
</style>
